.modal {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: 200ms ease-in-out opacity, 200ms ease-in-out z-index, 200ms ease-in-out visibility;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  overflow-y: auto;

  .backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #707070;
    opacity: 0.5;
    z-index: -1;
  }

  .container {
    display: inline-block;
    text-align: left;
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 38px 0 rgba(0, 0, 0, 0.3);
    padding: 12px;
    transition: 200ms ease-in-out transform;
    transform: scale(1.1);
    max-width: 400px;

    .message {
      color: black;
      padding: 24px;
      text-align: center;
    }
  }

  &.show {
    visibility: visible;
    z-index: 10000;
    opacity: 1;

    .container {
      transform: scale(1);
    }
  }

  .form-controls {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      &:hover,
      &:focus {
        background-color: #DDDDDD;
      }
    }
  }
}

.modal-background-blur {
  filter: blur(3px);
}