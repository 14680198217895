@import '../components/styles/Colors';

.preview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .signature-container {
    min-width: 400px;
    padding: 16px;
    background-color: white;
    //user-select: none;

    * {
      line-height: normal;
    }
  }

  .controls {
    text-align: center;
    margin-top: 12px;
  }
}