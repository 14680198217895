@mixin addFont($fontFamily, $fontFile, $fontWeight: normal, $fontStyle: normal) {
  @font-face {
  font-family: $fontFamily;
  src: url('../fonts/' + $fontFile + '.woff2') format('woff2'),
  url('../fonts/' + $fontFile + '.woff') format('woff');
  font-weight: $fontWeight;
  font-style: $fontStyle;
  font-display: swap;
  }
}

$ibmPlexSans: 'IBM Plex Sans';
$robotoCondensed: 'Roboto Condensed';