@import "Fonts";
@import "Colors";
@import "Form";

@include addFont($ibmPlexSans, 'ibmplexsans-italic-semibold', bold, italic);
@include addFont($ibmPlexSans, 'ibmplexsans-italic', normal, italic);
@include addFont($ibmPlexSans, 'ibmplexsans-bold', bold, normal);
@include addFont($ibmPlexSans, 'ibmplexsans-semibold', 600, normal);
@include addFont($ibmPlexSans, 'ibmplexsans-regular', normal, normal);
@include addFont($robotoCondensed, 'robotocondensed-regular', normal, normal);

* {
  margin: 0;
  font-family: $ibmPlexSans;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  line-height: 24px;
  color: $colorText;
}

html, body {
  background-color: $colorBackground;
  height: 100%;
  position: relative;
}

#root {
  display: flex;
  height: 100%;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
  min-width: 800px;
}

img {
  vertical-align: middle;
}

h1 {
  font-family: $robotoCondensed;
  font-size: 22px;
  line-height: 30px;
  font-weight: bold;
  text-transform: uppercase;
}