@import "Colors";

.field-group {
  display: block;
  margin-bottom: 16px;
}

label {
  display: block;
  font-weight: bold;
  vertical-align: middle;
  margin-bottom: 8px;
  color: $colorLabel;
}

textarea,
select,
input {
  padding: 0 0 5px 5px;
  width: 100%;
  background-color: $colorBackground;
  border: none;
  border-bottom: 1px solid #fff
}

input[type='checkbox'],
input[type='radio'] {
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
  width: unset;
}

.field-inputs {
  label {
    font-weight: normal;
    display: flex;
    align-items: center;
    margin-bottom: 6px;
    color: $colorText;
    text-align: left;
  }
}

button {
  font-size: 18px;
  line-height: 28px;
  color: #2D2D2D;
  background-color: #1FDF6F;
  padding-left: 5px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  transition: background-color ease-in-out 200ms;

  &:focus,
  &:hover,
  {
    background-color: #fff;
  }

  &:after {
  content: ' >>>'
  }
}