.signature-form {
  display: flex;
  height: 100%;
  align-items: center;
  .badges {
    .field-inputs {
      display: grid;
      grid-template-columns: 50% 50%;
    }
  }
}