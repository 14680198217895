.signature-generator {
  display: flex;
  flex-direction: column;
  align-items: center;

  .header {
    text-align: center;
    margin-bottom: 32px;

    h1 {
      margin: 8px 0;
    }
  }

  .body {
    display: flex;
    position: relative;
    vertical-align: middle;

    > div {
      padding: 16px;
    }
  }
}